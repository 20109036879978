<template>
  <v-card max-width="400" class="map-settings">
    <v-card-title class="close-btn">
      <slot name="title">
        Filter by
        <v-spacer></v-spacer>
        <v-btn
          @click="reset()"
          color="indigo lighten-4"
          text
          class="text-capitalize v-btn--active"
          x-small
          ><span class="indigo--text">Reset</span></v-btn
        >
        <v-btn @click="$emit('close')" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </slot>
    </v-card-title>
    <v-card-text class="mt-n4">
      <div style="">
        <div v-if="$store.getters.parcelSetting.team && showFilter('team')">
          <h5 class="text-h6">Teams</h5>
          <base-filter
            class="mt-1"
            :items="teams"
            defaultColor=""
            active-color="indigo"
            active-dark
            flat
            :showTitle="false"
            v-model="team"
            :showClearButton="false"
          ></base-filter>
        </div>
        <div v-if="preserves && preserves.length > 1 && showFilter('landPreserve')">
          <h5 class="text-h6">Preserve</h5>
          <base-filter
            class="mt-1"
            :items="preserves"
            defaultColor=""
            active-color="primary"
            flat
            :showTitle="false"
            v-model="preserve"
            :showClearButton="false"
          ></base-filter>
          <div v-if="preserve && showFilter('showPreserve')">
            <h6 class="text-h6">Show</h6>
            <v-radio-group row dense class="mt-0" v-model="showPreserve">
              <v-radio value="selected" :label="`Only ${startCase(preserve)}`"></v-radio>
              <v-radio value="all" :label="`Other and highlight ${startCase(preserve)}`"></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div v-if="showFilter('ownership')">
          <h5 class="text-h6">Ownership</h5>
          <base-filter
            :default="['']"
            multiple
            class="mt-1 mb-0"
            :items="landTypes"
            item-style
            defaultColor=""
            outlined
            flat
            :showTitle="false"
            v-model="landType"
            :showClearButton="false"
          ></base-filter>
        </div>
        <div v-if="showFilter('monitoringDue')">
          <h5 class="text-h6">Monitoring Due</h5>
          <div class="d-flex flex-wrap align-center">
            <v-sheet width="70" class="mt-1">
              <v-checkbox dense v-model="monitoringDueAny" label="Any"></v-checkbox>
            </v-sheet>
            <base-slider
              lazy
              class="mt-4"
              min="0"
              max="60"
              v-model="monitoringDue"
              thumb-label="always"
            ></base-slider>
            <v-sheet width="90" class="mt-1">
              <v-checkbox dense v-model="monitoringDueMissing" label="Missing"></v-checkbox>
            </v-sheet>
            <v-chip
              @click="toggleLandIssue()"
              small
              class="text-capitalize mt-n2 ml-1"
              :outlined="landIssue === 'ALL'"
              color="warning"
            >
              Issues
            </v-chip>
          </div>
        </div>
        <v-row class="no-gutters">
          <v-col cols="6" v-if="parcelReference && showFilter('reference')" v-bind="colClass">
            <div>
              <v-switch dense v-model="reference" label="Reference"></v-switch>
            </div>
          </v-col>
          <v-col cols="6" v-if="profile.enableNearbyParcels && showFilter('tot')" v-bind="colClass">
            <div>
              <v-switch dense label="Town" v-model="tot"></v-switch>
            </div>
          </v-col>
          <v-col cols="12" v-if="tot">
            <v-radio-group row v-model="totMode" dense>
              <v-radio dense value="all" label="All Town"/>
              <v-radio dense value="nearby" label="Nearby Town"/>
            </v-radio-group>
          </v-col>
          <v-col cols="6" v-if="showFilter('parcelId')" v-bind="colClass">
            <div>
              <v-switch dense v-model="showParcelId" label="Parcel ID"></v-switch>
            </div>
          </v-col>
          <v-col cols="6" v-if="profile.enableNearbyParcels && showFilter('tct')" v-bind="colClass">
            <div>
              <v-switch dense label="Nearby TCT" v-model="tct"></v-switch>
            </div>
          </v-col>
          <v-col cols="6" v-if="showFilter('acres')" v-bind="colClass">
            <div>
              <v-switch dense v-model="acres" label="Acres"></v-switch>
            </div>
          </v-col>
          <v-col cols="6" v-if="showFilter('scale')" v-bind="colClass">
            <div>
              <v-switch dense v-model="scale" label="Scale"></v-switch>
            </div>
          </v-col>
          <v-col
            cols="6"
            v-if="showFilter('boundary') && loggedInAs.type === 'organization'"
            v-bind="colClass"
          >
            <div>
              <v-switch dense v-model="boundary" label="Truro Boundary"></v-switch>
            </div>
          </v-col>
          <v-col
            cols="6"
            v-if="showFilter('monitoringColor') && loggedInAs.type === 'organization'"
            v-bind="colClass"
          >
            <div>
              <v-switch dense v-model="monitoringColor" label="Show monitoring color"></v-switch>
            </div>
          </v-col>
        </v-row>
        <div v-if="linkedGroups && Array.isArray(linkedGroups) && linkedGroups.length > 0">
          <h5 class="text-h6 mb-2">Linked Groups</h5>
          <v-row no-gutters>
            <v-col cols="6">
              <v-checkbox
                multiple
                class="my-0 py-0"
                v-for="group in linkedGroups"
                :key="group"
                v-model="linkedGroupGeometry"
                :value="group"
                :label="group"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                class="my-0 py-0"
                multiple
                v-for="group in linkedGroups"
                :value="group"
                :key="group"
                v-model="linkedGroupGeometryLabel"
                label="Labels"
              ></v-checkbox>
            </v-col>
          </v-row>
        </div>
        <div v-if="showFilter('style')">
          <h5 class="text-h6">Map Style</h5>
          <div>
            <v-chip
              :small="$vuetify.breakpoint.smAndDown"
              v-for="(s, index) in mapStyleList"
              :key="index"
              class="my-1 map-style-chip"
              @click="style = s.style"
              :outlined="s.style !== style"
              :color="s.style === style ? 'primary' : ''"
            >
              {{ s.text }}
            </v-chip>
          </div>
        </div>
      </div>
      <slot name="footer"></slot>
      <div v-if="parcels && showStat" class="mt-n4">
        <v-divider></v-divider>
        <div class="d-flex align-center mt-2">
          <h6 class="text-h6 total-title">Total:</h6>
          <ParcelStat small :value="parcels" />
        </div>
      </div>
      <!-- <div class="mt-2">
        <v-icon color="primary" class="mr-1">mdi-sort</v-icon>
        <v-btn
          color="primary"
          x-small
          :outlined="sort !== 'az'"
          @click="sort = 'az'"
          class="mr-1 text-capitalize"
          >AZ</v-btn
        >
        <v-btn
          color="primary"
          x-small
          class="text-capitalize"
          :outlined="sort !== 'recentUpdated'"
          @click="sort = 'recentUpdated'"
          >Recent</v-btn
        >
      </div> -->
    </v-card-text>
  </v-card>
</template>

<script>
import colors from '../../design/_colors.scss';
import { teams } from '../../utils/parcel';
import { mapGetters } from 'vuex';
import Land from '../../services/land';
import _ from 'lodash';

const fields = [
  'teams',
  'landPreserve',
  'ownership',
  'monitoringDue',
  'reference',
  'tot',
  'parcelId',
  'tct',
  'acres',
  'scale',
  'boundary',
  'style',
  'showPreserve',
  'monitoringColor'
];

export default {
  props: {
    showStat: {
      type: Boolean,
      default: false
    },
    show: {
      type: Array,
      default: () => {
        return [];
      }
    },
    exclude: {
      type: Array,
      default: () => []
    },
    parcels: {
      type: Array,
      default: () => []
    },
    linkedGroups: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    ParcelStat: () => import('./ParcelStat.vue')
  },
  data() {
    return {
      foo: [],
      colClass: {
        class: 'mt-n3'
      },
      landTypes: [{ text: 'All', value: '', style: { color: 'primary' } }, ...Land.landTypes()]
    };
  },
  created() {
    this.setting = { ...this.$store.state.parcel.mapConfig };
  },
  computed: {
    ...mapGetters(['parcelReference']),
    preserves() {
      return [
        { text: 'All', value: '' },
        ...(this.$store.state.landPreserves || []),
        { text: 'None', value: 'NONE' }
      ];
    },
    loggedInAs() {
      return this.$store.state.loggedInAs;
    },
    mapConfig() {
      return this.$store.state.parcel.mapConfig;
    },
    profile() {
      return this.$store.getters.user.profile;
    },
    colors() {
      return colors;
    },
    settingStringified() {
      return JSON.stringify(this.setting);
    },
    teams() {
      let parcels = this.$store.getters.parcels;
      parcels = parcels.filter(p => !!p.geojson || !!p.center);
      return teams(parcels, true, true);
    },
    //filters
    team: {
      get() {
        return this.mapConfig.team;
      },
      set(value) {
        this.setMapConfig({ key: 'team', value });
      }
    },

    sort: {
      get() {
        return this.mapConfig.sort;
      },
      set(value) {
        this.setMapConfig({ key: 'sort', value });
      }
    },

    monitoringColor: {
      get() {
        return this.mapConfig.monitoringColor;
      },
      set(value) {
        this.setMapConfig({ key: 'monitoringColor', value });
      }
    },

    preserve: {
      get() {
        return this.mapConfig.landPreserve;
      },
      set(value) {
        this.setMapConfig({ key: 'landPreserve', value });
      }
    },
    showPreserve: {
      get() {
        return this.mapConfig.showPreserve;
      },
      set(value) {
        this.setMapConfig({ key: 'showPreserve', value });
      }
    },
    landType: {
      get() {
        return this.mapConfig.land;
      },
      set(value) {
        this.setMapConfig({ key: 'land', value });
      }
    },
    acres: {
      get() {
        return this.mapConfig.acres;
      },
      set(value) {
        this.setMapConfig({ key: 'acres', value });
      }
    },
    scale: {
      get() {
        return this.mapConfig.scale;
      },
      set(value) {
        this.setMapConfig({ key: 'scale', value });
      }
    },
    reference: {
      get() {
        return this.mapConfig.reference;
      },
      set(value) {
        this.setMapConfig({ key: 'reference', value });
      }
    },
    tct: {
      get() {
        return this.mapConfig.tct;
      },
      set(value) {
        this.setMapConfig({ key: 'tct', value });
      }
    },
    totMode: {
      get() {
        return this.mapConfig.totMode;
      },
      set(value) {
        this.setMapConfig({ key: 'totMode', value });
      }
    },
    tot: {
      get() {
        return this.mapConfig.tot;
      },
      set(value) {
        this.setMapConfig({ key: 'tot', value });
      }
    },
    style: {
      get() {
        return this.mapConfig.style;
      },
      set(value) {
        this.setMapConfig({ key: 'style', value });
      }
    },
    showParcelId: {
      get() {
        return this.mapConfig.showParcelId;
      },
      set(value) {
        this.setMapConfig({ key: 'showParcelId', value });
      }
    },
    monitoringDueAny: {
      get() {
        return this.mapConfig.monitoringDueAny;
      },
      set(value) {
        this.setMapConfig({ key: 'monitoringDueAny', value });
      }
    },
    monitoringDue: {
      get() {
        return this.mapConfig.monitoringDue;
      },
      set(value) {
        this.setMapConfig({ key: 'monitoringDue', value });
      }
    },
    monitoringDueMissing: {
      get() {
        return this.mapConfig.monitoringDueMissing;
      },
      set(value) {
        this.setMapConfig({ key: 'monitoringDueMissing', value });
      }
    },
    boundary: {
      get() {
        return this.mapConfig.boundary;
      },
      set(value) {
        this.setMapConfig({ key: 'boundary', value });
      }
    },
    landIssue() {
      return this.mapConfig.landIssue || 'ALL';
    },
    linkedGroupGeometry: {
      get() {
        return this.mapConfig.linkedGroupGeometry;
      },
      set(value) {
        this.setMapConfig({ key: 'linkedGroupGeometry', value });
      }
    },
    linkedGroupGeometryLabel: {
      get() {
        return this.mapConfig.linkedGroupGeometryLabel;
      },
      set(value) {
        this.setMapConfig({ key: 'linkedGroupGeometryLabel', value });
      }
    }
  },
  methods: {
    toggleLandIssue() {
      const ISSUE_EXISTS = Land.issueStates().ISSUE_EXISTS;
      if (this.landIssue === ISSUE_EXISTS) {
        this.setMapConfig({ key: 'landIssue', value: 'ALL' });
      } else {
        this.setMapConfig({ key: 'landIssue', value: ISSUE_EXISTS });
      }
    },
    reset() {
      this.$store.dispatch('resetParcelFilter');
    },
    startCase(str) {
      return _.startCase(str);
    },
    setMapConfig(args) {
      this.$store.commit('setMapConfig', args);
    },
    showFilter(field) {
      if (this.exclude.includes(field)) {
        return false;
      }

      if (this.show.length === 0) {
        return true;
      }
      return this.show.includes(field);
    }
  }
};
</script>

<style lang="scss" scoped>
.map-settings {
  opacity: 1;
  position: relative;
}
.close-btn {
  // position: absolute;
  // right: 0;
  // z-index: 999;
}
.map-style-chip {
  margin-right: 2px;
}
</style>
